import React from 'react';
import {Button, Typography} from "@mui/material";


const ModuleOne: React.FC = () => {


    return (
        <div>
            <h1>Module One</h1>
            <h2>Make this fancy!</h2>

        </div>
    );
};
export default ModuleOne;