import React from 'react';


const ModuleTwo: React.FC = () => {
    return (
        <div>
            <h1>Module Two</h1>
            <h2>Make this even more fancy!</h2>
        </div>
    );
};
export default ModuleTwo;